import React from 'react';
import './components/style.css';
import './bootstrap-4.4.1-dist/css/bootstrap.min.css';
import styled from 'styled-components';

export const Faqs = () => (
	<div id="home">
		<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
			<a class="navbar-brand" href="/"><img src="images/Logoo.png" /></a>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div class="collapse navbar-collapse" id="navbarResponsive">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item">
						<a class="nav-link" href="/">Home</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="about">About Us</a>
					</li>
			        <li class="nav-item dropdown">
			          <a class="nav-link" href="services" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">BUSINESS UNITS</a>
			          <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
			            <a class="dropdown-item" href="electricalWorks">Electrical Works</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="solarSolutions">Solar Solutions</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="fireProtection">Fire Protection</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="operations&Maintenance">Operations & Maintenance</a>
			          </div>
			        </li>
					        <li class="nav-item dropdown">
				            <a class="nav-link uppercase outline" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Projects</a>
				            <div class="dropdown-menu dropdown-menu-right">
				                <a class="dropdown-item uppercase" href="electricalProjects">ELECTRICAL</a>
				                <div class="dropdown-divider"></div>
				                <a class="dropdown-item uppercase" href="solarProjects">SOLAR</a>
				                <div class="dropdown-divider"></div>
				                <a class="dropdown-item uppercase" href="fireProjects">FIRE SAFETY</a>
				            </div>
				        </li>
					<li class="nav-item">
						<a class="nav-link active" href="#">FAQS</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="contact">Contact</a>
					</li>
				</ul>
			</div>
		</nav>
		
		<div id="faqs" class="offset">
			<div class="narrow text-center">
				<div class="col-md-9 narrow text-center">
					<h3 class="heading">FAQS</h3>
					<div class="heading-underline"></div>
					<div class='centerplease'>
					</div>
					<br />

					<div class="content">
						<h2>SOLAR</h2>
						<div>
						  <input type="radio" id="question1" name="q"  class="questions" />
						  <div class="plus">+</div>
						  <label for="question1" class="question text-left">
						    <strong>How do solar Panels work?</strong>
						  </label>
						  <div class="answers">
						    Solar panels absorb the sun's energy throughout the day and convert it into direct current (DC) electricity. Most homes and businesses run on alternating current (AC) electricity, so the DC electricity is then passed through an inverter to convert it to usable AC electricity.
						  </div>
						</div>

						<div>
						  <input type="radio" id="question2" name="q" class="questions" />
						  <div class="plus">+</div>
						  <label for="question2" class="question text-left">
						    <strong>Do solar panels produce energy when the sun isn’t shinning?</strong>
						  </label>
						  <div class="answers">
						    The amount of power your solar energy system can generate is dependent on sunlight. As a result, your solar panels will produce slightly less energy when the weather is cloudy, and no energy at night.
						  </div>
						</div>
						  
						<div>
						  <input type="radio" id="question3" name="q" class="questions" />
						  <div class="plus">+</div>
						  <label for="question3" class="question text-left">
						    <strong>Will I receive Electric bills if I have solar panels?</strong>
						  </label>
						  <div class="answers">
						    Unless your solar energy system includes battery storage and you are fully off the grid, you will still receive a bill from your utility. However, you can dramatically reduce your bill.
						  </div>
						</div>

						<div>
						  <input type="radio" id="question4" name="q" class="questions" />
						  <div class="plus">+</div>
						  <label for="question4" class="question text-left">
						    <strong>Should I ask for a solar monitoring system for my solar Installation?</strong>
						  </label>
						  <div class="answers">
						    If you are interested in receiving detailed information about how much energy your solar panel system is producing, a monitoring system is very important.
						  </div>
						</div>

						<div>
						  <input type="radio" id="question5" name="q" class="questions" />
						  <div class="plus">+</div>
						  <label for="question5" class="question text-left">
						    <strong>How do I choose a solar Installer?</strong>
						  </label>
						  <div class="answers">
						    There are a few criteria that everyone should use when choosing a solar installer. Confirm that they are certified, licensed and insured, have relevant experience, and can provide references.
						  </div>
						</div>

						<div>
						  <input type="radio" id="question6" name="q" class="questions" />
						  <div class="plus">+</div>
						  <label for="question6" class="question text-left">
						    <strong>What are the financial benefits of solar?</strong>
						  </label>
						  <div class="answers">
						    When you install a solar energy system on your property, you save money on your electricity bills and protect yourself against rising electricity rates in the future. How much you can save depends on the utility rates and solar policies in your area, but going solar is a smart investment regardless of where you live.
						  </div>
						</div>

						<h2>FIRE SAFETY</h2>

						<div>
						  <input type="radio" id="question7" name="q" class="questions" />
						  <div class="plus">+</div>
						  <label for="question7" class="question text-left">
						    <strong>What is FM-200 Clean Agent?</strong>
						  </label>
						  <div class="answers">
						    FM-200™, also commonly known as HFC-227ea, is a clean agent fire suppressant for Class A, B, and C fires and meets NFPA Standard 2001 Clean Agent Fire Extinguishing Systems. Clean Agents are fast and effective in suppressing fires, are safe in occupied spaces, and do not leave a residue.
						  </div>
						</div>

						<div>
						  <input type="radio" id="question8" name="q" class="questions" />
						  <div class="plus">+</div>
						  <label for="question8" class="question text-left">
						    <strong>How Does FM-200™ Work?</strong>
						  </label>
						  <div class="answers">
						    FM-200 suppresses fire by disrupting the fire triangle. The fire triangle is made up of heat, oxygen, and a fuel source, and the fire is extinguished by removing one of these elements. FM-200 removes the heat or free radicals, which interferes with the fire triangle's chemical reaction to extinguish the fire. Clean agent fire suppression systems, can achieve fire extinguishing concentrations in 10 seconds or less.<br/>
							<strong>Advantages:</strong><br/>
							•	FM-200 is a clean agent that is safe around people; therefore, FM-200 fire suppression systems are safe to install in occupied spaces.<br/>
							•	When an FM-200 fire suppression system activates to put out a fire, no residue is left behind, and it is safe for equipment, electronics, and machinery.<br/>
							•	Compared to other clean agents like 3M Novec 1230 Fire Protection Fluid, FM-200 is a slightly more cost-effective option.<br/>
							•	Because of the small amount of FM-200 required to suppress a fire, smaller or fewer cylinders are needed when compared to other agents like 3M Novec 1230 fluid.<br/>
							<strong>Disadvantages:</strong><br/>
							•	When compared to other clean agents, FM-200 has a slightly higher Global Warming Potential (GWP).<br/>
							•	While the FM-200 does not contain Halon gas, it still uses Hydrofluorocarbons (HFC) to suppress fires, which is not as eco-friendly as other clean agents.<br/>
						  </div>
						</div>

						<div>
						  <input type="radio" id="question9" name="q" class="questions" />
						  <div class="plus">+</div>
						  <label for="question9" class="question text-left">
						    <strong>What is the Chemical Name for FM-200 Gas?</strong>
						  </label>
						  <div class="answers">
						    The chemical name for FM-200 is 1,1,1,2,3,3,3-Heptafluoropropane. It is also referred to as hydrofluorocarbon (HFC) 227ea and is manufactured by Chemours under the trademark FM-200. The gas is a colorless, compressed liquefied gas used to extinguish fires and is a popular replacement for Halon fire suppression systems. FM-200 fire suppression systems are pressurized with nitrogen, waterless, and upon activation, FM-200 discharges as a gas to suppress the fire.
						  </div>
						</div>

						<div>
						  <input type="radio" id="question10" name="q" class="questions" />
						  <div class="plus">+</div>
						  <label for="question10" class="question text-left">
						    <strong>Is FM-200™ Gas Dangerous?</strong>
						  </label>
						  <div class="answers">
						  	The gas is low in toxicity and does not pose acute or long-term dangers when handled per the manufacturer’s recommendation. The vapors from FM-200 have little to no effect on the skin or eyes, but when in liquid form, on contact, it can freeze the skin or eyes, causing frostbite. In the case of inhaling high concentrations of FM-200 vapors, over the recommended exposure limits, one may experience headache, dizziness, loss of coordination, confusion, and in a worst-case scenario, loss of consciousness. When following manufacturers' guidelines, FM-200 is safe for use in fully occupied areas.
						  </div>
						</div>

						<div>
						  <input type="radio" id="question11" name="q" class="questions" />
						  <div class="plus">+</div>
						  <label for="question11" class="question text-left">
						    <strong>What is the Difference Between FM-200™ and 3M Novec 1230 Fluid?</strong>
						  </label>
						  <div class="answers">
						  	Suppression systems using FM-200™ and 3M Novec 1230 Fluid are similar in that they are both safe to use in occupied spaces, do not damage sensitive equipment, and do not require to clean up. While both substances are fast-acting, clean-agent fire suppression systems, they differ slightly. 3M Novec 1230 fluid's chemical compound is fluoroketone and is stored as a liquid and discharges as a gas. Depending on the area you are protecting, these systems may require larger or more cylinders than an FM-200 system. Both substances cause no ozone depletion and are considered environmentally friendly; however, 3M Novec 1230 fluid does have a lower GWP. 
						  </div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<a href="https://api.whatsapp.com/send?phone=254737322874&text=Hello" class="float" target="_blank">
		<i class="fa fa-whatsapp my-float"></i>
		</a>

		<div id="contact" class="offset">
		    <footer class="site-footer">
		      <div class="container">
		        <div class="row">
		          <div class="col-sm-12 col-md-6">
		            <h6>BUSINESS UNITS</h6><br/>
		            <ul class="footer-links">
		              <li><a href="electricalWorks">ELECTRICAL WORKS</a></li>
		              <li><a href="solarSolutions">SOLAR SOLUTIONS</a></li>
		              <li><a href="fireProtection">FIRE SAFETY</a></li>
		              <li><a href="operations&Maintenance">OPERATIONS & MAINTENANCE</a></li>
		            </ul>
		          </div>

		          <div class="col-xs-6 col-md-3">
		            <h6>PROJECTS</h6><br />
		            <ul class="footer-links">
		              <li><a href="electricalProjects">ELECTRICAL</a></li>
		              <li><a href="solarProjects">SOLAR</a></li>
		              <li><a href="fireProjects">FIRE SAFETY</a></li>
		            </ul>
		          </div>

		          <div class="col-xs-6 col-md-3">
		            <h6>Quick Links</h6><br />
		            <ul class="footer-links">
		              <li><a href="/">HOME</a></li>
		              <li><a href="about">ABOUT US</a></li>
		              <li><a href="#">FAQS</a></li>
		              <li><a href="contact">CONTACT US</a></li>
		            </ul>
		          </div>
		        </div>
		        <hr />
		      </div>
		      <div class="container">
		        <div class="row">
		          <div class="col-md-8 col-sm-6 col-xs-12">
		            <p class="copyright-text">Copyright &copy; 2023 All Rights Reserved by 
		         <a href="/"> Oktagon East Africa</a>.
		            </p>
		          </div>

		          <div class="col-md-4 col-sm-6 col-xs-12">
		            <ul class="social-icons">
		              <li><a class="facebook"><i class="fa fa-facebook"></i></a></li>
		              <li><a class="twitter"><i class="fa fa-twitter"></i></a></li>
		              <li><a class="linkedin"><i class="fa fa-linkedin"></i></a></li> 
		              <li><a class="instagram"><i class="fa fa-instagram"></i></a></li>  
		            </ul>
		          </div>
		        </div>
		      </div>
			</footer>
		</div>
	</div>
)