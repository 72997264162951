import React from 'react';
import './components/style.css';
import './bootstrap-4.4.1-dist/css/bootstrap.min.css';
import styled from 'styled-components';

export const ElectricalWorks = () => (
	<div id="home">
		<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
			<a class="navbar-brand" href="/"><img src="images/Logoo.png" /></a>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div class="collapse navbar-collapse" id="navbarResponsive">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item">
						<a class="nav-link" href="/">Home</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="about">About Us</a>
					</li>
			        <li class="nav-item dropdown">
			          <a class="nav-link active" href="services" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">BUSINESS UNITS</a>
			          <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
			            <a class="dropdown-item" href="electricalWorks">Electrical Works</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="solarSolutions">Solar Solutions</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="fireProtection">Fire Protection</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="operations&Maintenance">Operations & Maintenance</a>
			          </div>
			        </li>
					        <li class="nav-item dropdown">
				            <a class="nav-link uppercase outline" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Projects</a>
				            <div class="dropdown-menu dropdown-menu-right">
				                <a class="dropdown-item uppercase" href="electricalProjects">ELECTRICAL</a>
				                <div class="dropdown-divider"></div>
				                <a class="dropdown-item uppercase" href="solarProjects">SOLAR</a>
				                <div class="dropdown-divider"></div>
				                <a class="dropdown-item uppercase" href="fireProjects">FIRE SAFETY</a>
				            </div>
				        </li>
					<li class="nav-item">
						<a class="nav-link" href="faqs">FAQS</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="contact">Contact</a>
					</li>
				</ul>
			</div>
		</nav>

		<div id="services" class="offset">
			<div class="narrow text-center">
				<div class="col-md-10 narrow text-center">
					<h3 class="heading">Electrical Works</h3>
					<div class="heading-underline"></div>

						<div class="row text-center">
							<div class="col-md-7">
								<div class="feature">
									<div class="feature">
										<h3>INSTALL, TEST, AND COMMISSION</h3>	
										<p>Oktagon E.A Ltd offer a range of tests that can be completed either in our factory or on-site by one of our skilled testing engineers. These tests include continuity, current injection, flash/pressure testing, insulation resistance, load testing, and polarity. We offer an on-site installation and Site Acceptance Test (SAT). Oktagon E.A provides certification documents and reports upon completion of the installation, the FAT/SAT test, and commissioning.</p>		
									</div>
								</div>
							</div>

							<div class="col-md-5">
								<div class="feature">
									<figure class="snip0018">
										<img src="images/assembly-electrical-panelrs.jpg" />
										<figcaption>
											<div><h2>Electrical panel assembly</h2></div>
										</figcaption>			
									</figure>
								</div>
							</div>

							<div class="col-md-7">
								<div class="feature">
									<figure class="snip0018">
										<img src="images/IMG-20230219-WA0012.jpg" />
										<figcaption>
											<div><h2>LV SWITCHGEAR</h2></div>
										</figcaption>			
									</figure>
								</div>
							</div>

							<div class="col-md-5">
								<div class="feature">
									<div class="feature">
										<h3>LV SWITCHGEAR</h3>
										<p>LV Switchgear is at the forefront of Oktagon E.A Ltd, with each individual project being assigned an engineer to ensure that the bespoke panel is designed best to suit our client’s requirements. </p>
									</div>
								</div>
							</div>

							<div class="col-md-7">
								<div class="feature">
									<div class="feature">
										<h3>MOTOR CONTROL SWITCHGEAR</h3>
										<p>Motor Control switchgear is typically used in manufacturing industries to control the motors that form part of the various assembly lines and machinery. They are similar in build and appearance to the standard LV Switchboards but often include Variable Frequency Drives to adjust the speed and torque of the AC motors in the specific plant.</p>
									</div>
								</div>
							</div>

							<div class="col-md-5">
								<div class="feature">
									<figure class="snip0018">
										<img src="images/voltage-distributor-with-automatic-switches.jpg" />
										<figcaption>
											<div><h2>MOTOR CONTROL SWITCHGEAR</h2></div>
										</figcaption>			
									</figure>
								</div>
							</div>
						</div>
					
					<div class="row text-center">
						<div class="col-md-4">
							<div class="feature">
								<figure class="snip0018">
									<img src="images/PFC Bank.jpg" />
									<figcaption>
										<div><h2>PFC Bank</h2></div>
									</figcaption>			
								</figure>
							</div>
						</div>

						<div class="col-md-4">
							<div class="feature">
								<figure class="snip0018">
									<img src="images/electric-g3d1b1c4a3_1920.jpg" />
									<figcaption>
										<div><h2>ELECTRICAL DISTRIBUTION BOARD</h2></div>
									</figcaption>			
								</figure>
							</div>
						</div>

						<div class="col-md-4">
							<div class="feature">
								<figure class="snip0018">
									<img src="images/IMG-20230219-WA0004.jpg" />
									<figcaption>
										<div><h2>OUTDOOR L.V ELECTRICAL BOARD</h2></div>
									</figcaption>			
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<a href="https://api.whatsapp.com/send?phone=254737322874&text=Hello" class="float" target="_blank">
		<i class="fa fa-whatsapp my-float"></i>
		</a>


		<div id="contact" class="offset">
		    <footer class="site-footer">
		      <div class="container">
		        <div class="row">
		          <div class="col-sm-12 col-md-6">
		            <h6>BUSINESS UNITS</h6><br/>
		            <ul class="footer-links">
		              <li><a href="electricalWorks">ELECTRICAL WORKS</a></li>
		              <li><a href="solarSolutions">SOLAR SOLUTIONS</a></li>
		              <li><a href="fireProtection">FIRE SAFETY</a></li>
		              <li><a href="operations&Maintenance">OPERATIONS & MAINTENANCE</a></li>
		            </ul>
		          </div>

		          <div class="col-xs-6 col-md-3">
		            <h6>PROJECTS</h6><br />
		            <ul class="footer-links">
		              <li><a href="electricalProjects">ELECTRICAL</a></li>
		              <li><a href="solarProjects">SOLAR</a></li>
		              <li><a href="fireProjects">FIRE SAFETY</a></li>
		            </ul>
		          </div>

		          <div class="col-xs-6 col-md-3">
		            <h6>Quick Links</h6><br />
		            <ul class="footer-links">
		              <li><a href="/">HOME</a></li>
		              <li><a href="about">ABOUT US</a></li>
		              <li><a href="faqs">FAQS</a></li>
		              <li><a href="contact">CONTACT US</a></li>
		            </ul>
		          </div>
		        </div>
		        <hr />
		      </div>
		      <div class="container">
		        <div class="row">
		          <div class="col-md-8 col-sm-6 col-xs-12">
		            <p class="copyright-text">Copyright &copy; 2023 All Rights Reserved by 
		         <a href="#"> Oktagon East Africa</a>.
		            </p>
		          </div>

		          <div class="col-md-4 col-sm-6 col-xs-12">
		            <ul class="social-icons">
		              <li><a class="facebook"><i class="fa fa-facebook"></i></a></li>
		              <li><a class="twitter"><i class="fa fa-twitter"></i></a></li>
		              <li><a class="linkedin"><i class="fa fa-linkedin"></i></a></li> 
		              <li><a class="instagram"><i class="fa fa-instagram"></i></a></li>  
		            </ul>
		          </div>
		        </div>
		      </div>
		</footer>
		</div>
	</div>
)