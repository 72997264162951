import React from 'react';
import './components/style.css';
import './bootstrap-4.4.1-dist/css/bootstrap.min.css';
import styled from 'styled-components';

export const FireProtection = () => (
	<div id="home">
		<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
			<a class="navbar-brand" href="/"><img src="images/Logoo.png" /></a>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div class="collapse navbar-collapse" id="navbarResponsive">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item">
						<a class="nav-link" href="/">Home</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="about">About Us</a>
					</li>
			        <li class="nav-item dropdown">
			          <a class="nav-link active" href="services" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">BUSINESS UNITS</a>
			          <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
			            <a class="dropdown-item" href="electricalWorks">Electrical Works</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="solarSolutions">Solar Solutions</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="fireProtection">Fire Protection</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="operations&Maintenance">Operations & Maintenance</a>
			          </div>
			        </li>
					        <li class="nav-item dropdown">
				            <a class="nav-link uppercase outline" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Projects</a>
				            <div class="dropdown-menu dropdown-menu-right">
				                <a class="dropdown-item uppercase" href="electricalProjects">ELECTRICAL</a>
				                <div class="dropdown-divider"></div>
				                <a class="dropdown-item uppercase" href="solarProjects">SOLAR</a>
				                <div class="dropdown-divider"></div>
				                <a class="dropdown-item uppercase" href="fireProjects">FIRE SAFETY</a>
				            </div>
				        </li>
					<li class="nav-item">
						<a class="nav-link" href="faqs">FAQS</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="contact">Contact</a>
					</li>
				</ul>
			</div>
		</nav>

		<div id="services" class="offset">
			<div class="narrow text-center">
				<div class="col-md-10 narrow text-center">
					<h3 class="heading">Fire Protection</h3>
					<div class="heading-underline"></div>

						<div class="row text-center">
							<div class="col-md-7">
								<div class="feature">
									<div class="feature">
										<h3>FIRE DETECTION & ALARM SYSTEMS</h3>	
										<ul>
											<li>Fire Alarm Panels; Conventional Panels & Addressable Panels</li>
											<li>Smoke Detectors</li>
											<li>Heat Detectors</li>
											<li>Sounders / Flashers</li>
											<li>Call Points</li>
										</ul>
									</div>
								</div>
							</div>

							<div class="col-md-5">
								<div class="feature">
									<figure class="snip0018">
										<img src="images/power-supply-box-fire-alarm-equipment-cctv-camera-security.jpg" />
										<figcaption>
											<div><h2>FIRE DETECTION & ALARM SYSTEMS</h2></div>
										</figcaption>			
									</figure>
								</div>
							</div>

							<div class="col-md-6">
								<div class="feature">
									<div class="feature">
										<h3>FIRE SUPPRESSION SYSTEMS AND SOLUTIONS</h3>
										<p>Fire Suppression Systems protect valuable resources and critical facilities. <br/>Well-designed fire suppression systems must be constructed on essential design principles, this is why our extinguishing systems are engineered with the following considerations:</p>
										<ul>
											<li>Cylinder storage space requirements</li>
											<li>Room Infrastructure, ensuring the enclosure is suitable for the fire suppression agent</li>
											<li>Safety to occupants, using ISO9001:2015 certified quality management procedures</li>
											<li>Environmentally friendly systems; Correct designs prevent unnecessary system discharges</li>
											<li>Accurate and reliable smoke detection technology</li>
											<li>Sustainable fire suppression technology, ensures users are well-informed about what they are installing </li>
										</ul>
									</div>
								</div>
							</div>

							<div class="col-md-6">
								<div class="feature">
									<figure class="snip0018">
										<img src="images/Fire Hydrant.jpg" />
										<figcaption>
											<div><h2>Fire Hydrant</h2></div>
										</figcaption>			
									</figure>
								</div>
							</div>

							<div class="col-md-7">
								<div class="feature">
									<figure class="snip0018">
										<img src="images/IMG_20180625_115129.jpg" />
										<figcaption>
											<div><h2>Fire pump set</h2></div>
										</figcaption>			
									</figure>
								</div>
							</div>

							<div class="col-md-5">
								<div class="feature">
									<div class="feature">
										<p>Four Types of Fire Suppression Systems that we offer:</p>
										<ul>
											<li>Clean Agents - FM – 200 & 3M Novec 1230 Fluid</li>
											<li>Carbon Dioxide</li>
											<li>Dry Chemical</li>
											<li>Water Suppression Systems</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						
					<div class="row text-center">
						<div class="col-md-4">
							<div class="feature">
								<figure class="snip0018">
									<img src="images/6.jpg" />
									<figcaption>
										<div><h2>FIRE CHART</h2></div>
									</figcaption>			
								</figure>
							</div>
						</div>

						<div class="col-md-4">
							<div class="feature">
								<figure class="snip0018">
									<img src="images/pexels-oluwaseun-duncan-189474.jpg" />
									<figcaption>
										<div><h2>Horse reel</h2></div>
									</figcaption>			
								</figure>
							</div>
						</div>

						<div class="col-md-4">
							<div class="feature">
								<figure class="snip0018">
									<img src="images/fire-fighting-systems-water-station-Header.jpg" />
									<figcaption>
										<div><h2>Fire water station header</h2></div>
									</figcaption>			
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<a href="https://api.whatsapp.com/send?phone=254737322874&text=Hello" class="float" target="_blank">
		<i class="fa fa-whatsapp my-float"></i>
		</a>

		<div id="contact" class="offset">
		    <footer class="site-footer">
		      <div class="container">
		        <div class="row">
		          <div class="col-sm-12 col-md-6">
		            <h6>BUSINESS UNITS</h6><br/>
		            <ul class="footer-links">
		              <li><a href="electricalWorks">ELECTRICAL WORKS</a></li>
		              <li><a href="solarSolutions">SOLAR SOLUTIONS</a></li>
		              <li><a href="fireProtection">FIRE SAFETY</a></li>
		              <li><a href="operations&Maintenance">OPERATIONS & MAINTENANCE</a></li>
		            </ul>
		          </div>

		          <div class="col-xs-6 col-md-3">
		            <h6>PROJECTS</h6><br />
		            <ul class="footer-links">
		              <li><a href="electricalProjects">ELECTRICAL</a></li>
		              <li><a href="solarProjects">SOLAR</a></li>
		              <li><a href="fireProjects">FIRE SAFETY</a></li>
		            </ul>
		          </div>

		          <div class="col-xs-6 col-md-3">
		            <h6>Quick Links</h6><br />
		            <ul class="footer-links">
		              <li><a href="/">HOME</a></li>
		              <li><a href="about">ABOUT US</a></li>
		              <li><a href="faqs">FAQS</a></li>
		              <li><a href="contact">CONTACT US</a></li>
		            </ul>
		          </div>
		        </div>
		        <hr />
		      </div>
		      <div class="container">
		        <div class="row">
		          <div class="col-md-8 col-sm-6 col-xs-12">
		            <p class="copyright-text">Copyright &copy; 2023 All Rights Reserved by 
		         <a href="/"> Oktagon East Africa</a>.
		            </p>
		          </div>

		          <div class="col-md-4 col-sm-6 col-xs-12">
		            <ul class="social-icons">
		              <li><a class="facebook"><i class="fa fa-facebook"></i></a></li>
		              <li><a class="twitter"><i class="fa fa-twitter"></i></a></li>
		              <li><a class="linkedin"><i class="fa fa-linkedin"></i></a></li> 
		              <li><a class="instagram"><i class="fa fa-instagram"></i></a></li>  
		            </ul>
		          </div>
		        </div>
		      </div>
			</footer>
		</div>
	</div>
)