import React from 'react';
import './components/style.css';
import './bootstrap-4.4.1-dist/css/bootstrap.min.css';
import styled from 'styled-components';

export const Home = () => (
	<div id="home">
		<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
			<a class="navbar-brand" href="#" style={{padding: '0'}}><img src="images/Logoo.png" /></a>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div class="collapse navbar-collapse" id="navbarResponsive">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item">
						<a class="nav-link active" href="#">Home</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="about">About Us</a>
					</li>
			        <li class="nav-item dropdown">
			          <a class="nav-link" href="services" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Business Units</a>
			          <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
			            <a class="dropdown-item" href="electricalWorks">Electrical Works</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="solarSolutions">Solar Solutions</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="fireProtection">Fire Protection</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="operations&Maintenance">Operations & Maintenance</a>
			          </div>
			        </li>
					        <li class="nav-item dropdown">
				            <a class="nav-link uppercase outline" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Projects</a>
				            <div class="dropdown-menu dropdown-menu-right">
				                <a class="dropdown-item uppercase" href="electricalProjects">ELECTRICAL</a>
				                <div class="dropdown-divider"></div>
				                <a class="dropdown-item uppercase" href="solarProjects">SOLAR</a>
				                <div class="dropdown-divider"></div>
				                <a class="dropdown-item uppercase" href="fireProjects">FIRE SAFETY</a>
				            </div>
				        </li>
					<li class="nav-item">
						<a class="nav-link" href="faqs">FAQS</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="contact">Contact</a>
					</li>
				</ul>
			</div>
		</nav>

		<div class="landing">
			<div class="home-wrap">
				<div class="home-inner"></div>
				<div id="demo" class="carousel slide" data-ride="carousel">
				  <ul class="carousel-indicators">
				    <li data-target="#demo" data-slide-to="0" class="active"></li>
				    <li data-target="#demo" data-slide-to="1"></li>
				    <li data-target="#demo" data-slide-to="2"></li>
				  </ul>
				  <div class="carousel-inner">
				    <div class="carousel-item">
				      <img src="images/TX.jpg" alt="Chicago" />
				      <div class="carousel-caption">
						<div class="col-md-10 caption text-center">
							<h2>ELECTRICAL</h2>
							<h3>Efficient & Innovative Energy Systems</h3>
							<a class="btn btn-outline-light btn-lg" href="#course">Get Started</a>
						</div>
				      </div>   
				    </div>
				    <div class="carousel-item">
				      <img src="images/Fire Extinguisher.jpg" alt="Los Angeles" />
				      <div class="carousel-caption">
						<div class="col-md-10 caption text-center">
							<h2>FIRE SAFETY</h2>
							<h3>Protect People & Property From Fire Related Hazards</h3>
							<a class="btn btn-outline-light btn-lg" href="#course">Get Started</a>
						</div>
				      </div>   
				    </div>
				    <div class="carousel-item active">
				      <img src="images/solar-panels-2458717_1920.jpg" alt="Chicago" />
				      <div class="carousel-caption">
						<div class="col-md-10 caption text-center">
							<h2>SOLAR</h2>
							<h3>Clean Energy Resource</h3>
							<a class="btn btn-outline-light btn-lg" href="#course">Get Started</a>
						</div>
				      </div>   
				    </div>
				  </div>
				  <a class="carousel-control-prev" href="#demo" data-slide="prev">
				    <span class="carousel-control-prev-icon"></span>
				  </a>
				  <a class="carousel-control-next" href="#demo" data-slide="next">
				    <span class="carousel-control-next-icon"></span>
				  </a>
				</div>
			</div>
			</div>


		<div id="course" class="offset">
			
			<div class="welcome">
				<div class="row light text-center">
					<div class="row text-center">
						<div class="col-md-7">
							<div class="feature">
								<h1>WHO WE ARE</h1>
								<div class="feature">
								</div>
									<p>
									Established in 2015 Oktagon E.A Ltd has specialized in efficient and sustainable solutions. We deliver Clientele projects via fully certified and accredited systems to a high level of customer satisfaction. We are equipped to design, deliver and install bespoke products engineered to facilitate individual client requirements.
									</p>
									<h2>OUR Key Areas of Expertise</h2>
									<ul>
										<li>Electrical Works</li>
										<li>Solar Works</li>
										<li>Fire Safety</li>
										<li>Operation & Mainte</li>
									</ul>
									<a class="btn btn-warning btn-md" href="about">Read More</a>
							</div>
						</div>

						<div class="col-md-4">
							<div class="feature">
								<figure class="snip0018">
									<img src="images/Page-5-Image-14.jpg" />
									<figcaption>
										<div><h2>Oktagon East Africa</h2></div>
										<div><p>Our Expertise</p></div>
									</figcaption>			
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>

			<div id="resources" class="offset">
			
				<div class="jumbotron">
					<div class="row light text-center">
						<div class="col-12">
							<h3 class="heading">BUSINESS UNITS</h3>
							<div class="heading-underline"></div>
						</div>
						<div class="row text-center">
			                <div class="row gy-4">

			                    <div class="col-md-3" data-aos="fade-up">
			                        <div class="card-custom rounded-4 bg-base shadow-effect">
			                            <div class="card-custom-image rounded-4">
			                                <img class="rounded-4" src="images/voltage-switchboard-with-circuit-breakers.jpg" alt=""/>
			                            </div>
			                            <div class="card-custom-content p-4">
			                                <h4>Electrical Works</h4>
			                                <a href="electricalWorks" class="link-custom">Read More</a>
			                            </div>
			                        </div>
			                    </div>

			                    <div class="col-md-3" data-aos="fade-up" data-aos-delay="300">
			                        <div class="card-custom rounded-4 bg-base shadow-effect">
			                            <div class="card-custom-image rounded-4">
			                                <img class="rounded-4" src="images/IMG-20230226-WA0000.jpg" alt=""/>
			                            </div>
			                            <div class="card-custom-content p-4">
			                                <h4>Solar Solutions</h4>
			                                <a href="solarSolutions" class="link-custom">Read More</a>
			                            </div>
			                        </div>
			                    </div>

			                    <div class="col-md-3" data-aos="fade-up">
			                        <div class="card-custom rounded-4 bg-base shadow-effect">
			                            <div class="card-custom-image rounded-4">
			                                <img class="rounded-4" src="images/05.jpg" alt=""/>
			                            </div>
			                            <div class="card-custom-content p-4">
			                                <h4>Fire Protection</h4>
			                                <a href="fireProtection" class="link-custom">Read More</a>
			                            </div>
			                        </div>
			                    </div>

			                    <div class="col-md-3" data-aos="fade-up" data-aos-delay="300">
			                        <div class="card-custom rounded-4 bg-base shadow-effect">
			                            <div class="card-custom-image rounded-4">
			                                <img class="rounded-4" src="images/Testing & Commissioning.jpg" alt=""/>
			                            </div>
			                            <div class="card-custom-content p-4">
			                                <h4>Operations & Maintenance</h4>
			                                <a href="operations&Maintenance" class="link-custom">Read More</a>
			                            </div>
			                        </div>
			                    </div>
			                </div>
						</div>
					</div>
				</div>
			</div>

		<div id="resources" class="offset">
			
			<div class="fixed-background col-md-12">
				<div class="row light text-center">
					<div class="col-12">
						<h3 class="heading">Projects</h3>
						<div class="heading-underline"></div>
					</div>
					<div class="row text-center">
		                <div class="row gy-4">

		                    <div class="col-md-3" data-aos="fade-up">
		                        <div class="card-custom rounded-4 bg-base shadow-effect">
		                            <div class="card-custom-image rounded-4">
		                                <img class="rounded-4" src="images/voltage-distributor-with-automatic-switches.jpg" alt=""/>
		                            </div>
		                            <div class="card-custom-content p-4">
		                                <h4>Electrical Works</h4>
		                                <a href="#" class="link-custom">Read More</a>
		                            </div>
		                        </div>
		                    </div>

		                    <div class="col-md-3" data-aos="fade-up" data-aos-delay="300">
		                        <div class="card-custom rounded-4 bg-base shadow-effect">
		                            <div class="card-custom-image rounded-4">
		                                <img class="rounded-4" src="images/IMG-20230219-WA0007.jpg" alt=""/>
		                            </div>
		                            <div class="card-custom-content p-4">
		                                <h4>Solar Solutions</h4>
		                                <a href="#" class="link-custom">Read More</a>
		                            </div>
		                        </div>
		                    </div>

		                    <div class="col-md-3" data-aos="fade-up">
		                        <div class="card-custom rounded-4 bg-base shadow-effect">
		                            <div class="card-custom-image rounded-4">
		                                <img class="rounded-4" src="images/pexels-oluwaseun-duncan-189474.jpg" alt=""/>
		                            </div>
		                            <div class="card-custom-content p-4">
		                                <h4>Fire Protection</h4>
		                                <a href="#" class="link-custom">Read More</a>
		                            </div>
		                        </div>
		                    </div>

		                    <div class="col-md-3" data-aos="fade-up" data-aos-delay="300">
		                        <div class="card-custom rounded-4 bg-base shadow-effect">
		                            <div class="card-custom-image rounded-4">
		                                <img class="rounded-4" src="images/IMG-20200801-WA0003.jpg" alt=""/>
		                            </div>
		                            <div class="card-custom-content p-4">
		                                <h4>Operations & Maintenance</h4>
		                                <a href="#" class="link-custom">Read More</a>
		                            </div>
		                        </div>
		                    </div>
		                </div>
					</div>
				</div>
			</div> 
		</div>

		<div id="clients" class="offset">
		
			<div class="patners">
				<div class="narrow text-center">
					<div class="col-md-10 narrow text-center">
					<h3 class="heading">Patners</h3>
					<div class="heading-underline"></div>
						<div class="row image center">
							<div class="col-md-2">
								<div class="feature" id="patners">
									<img src="images/hoppecke.png" />
								</div>
							</div>
							<div class="col-md-2">
								<div class="feature" id="patners">
									<img src="images/epra.jpg" />
								</div>
							</div>
							<div class="col-md-2">
								<div class="feature" id="patners">
									<img src="images/fronius.png" />
								</div>
							</div>
							<div class="col-md-2">
								<div class="feature" id="patners">
									<img src="images/VESDA-Logo.png" />
								</div>
							</div>
							<div class="col-md-2">
								<div class="feature" id="patners">
									<img src="images/ABB_logo.png" />
								</div>
							</div>
							<div class="col-md-2">
								<div class="feature" id="patners">
									<img src="images/jinko.jpg" />
								</div>
							</div>
							<div class="col-md-2">
								<div class="feature" id="patners">
									<img src="images/Fike.png" />
								</div>
							</div>
							<div class="col-md-2">
								<div class="feature" id="patners">
									<img src="images/sma.svg" />
								</div>
							</div>
							<div class="col-md-2">
								<div class="feature" id="patners">
									<img src="images/schneider-electric.png" />
								</div>
							</div>
							<div class="col-md-2">
								<div class="feature" id="patners">
									<img src="images/Zeta_Fire_Alarm_System.jpg" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<a href="https://api.whatsapp.com/send?phone=254737322874&text=Hello" class="float" target="_blank">
		<i class="fa fa-whatsapp my-float"></i>
		</a>

		<div id="contact" class="offset">
		    <footer class="site-footer">
		      <div class="container">
		        <div class="row">
		          <div class="col-sm-12 col-md-6">
		            <h6>BUSINESS UNITS</h6><br/>
		            <ul class="footer-links">
		              <li><a href="electricalWorks">ELECTRICAL WORKS</a></li>
		              <li><a href="solarSolutions">SOLAR SOLUTIONS</a></li>
		              <li><a href="fireProtection">FIRE SAFETY</a></li>
		              <li><a href="operations&Maintenance">OPERATIONS & MAINTENANCE</a></li>
		            </ul>
		          </div>

		          <div class="col-xs-6 col-md-3">
		            <h6>PROJECTS</h6><br/>
		            <ul class="footer-links">
		              <li><a href="electricalProjects">ELECTRICAL</a></li>
		              <li><a href="solarProjects">SOLAR</a></li>
		              <li><a href="fireProjects">FIRE SAFETY</a></li>
		            </ul>
		          </div>

		          <div class="col-xs-6 col-md-3">
		            <h6>Quick Links</h6><br/>
		            <ul class="footer-links">
		              <li><a href="#">HOME</a></li>
		              <li><a href="about">ABOUT US</a></li>
		              <li><a href="faqs">FAQS</a></li>
		              <li><a href="contact">CONTACT US</a></li>
		            </ul>
		          </div>
		        </div>
		        <hr/>
		      </div>
		      <div class="container">
		        <div class="row">
		          <div class="col-md-8 col-sm-6 col-xs-12">
		            <p class="copyright-text">Copyright &copy; 2023 All Rights Reserved by 
		         <a> Oktagon East Africa</a>.
		            </p>
		          </div>

		          <div class="col-md-4 col-sm-6 col-xs-12">
		            <ul class="social-icons">
		              <li><a class="facebook"><i class="fa fa-facebook"></i></a></li>
		              <li><a class="twitter"><i class="fa fa-twitter"></i></a></li>
		              <li><a class="linkedin"><i class="fa fa-linkedin"></i></a></li> 
		              <li><a class="instagram"><i class="fa fa-instagram"></i></a></li>  
		            </ul>
		          </div>
		        </div>
		      </div>
			</footer>
		</div>
	</div>
)
