import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './Home';
import { About } from './About';
import { Faqs } from './faqs';
import { Contact } from './Contact';
import { ElectricalWorks } from './electricalWorks';
import { SolarSolutions } from './solarSolutions';
import { FireProtection } from './fireProtection';
import { OperationsandMaintenance } from './operations&Maintenance';
import { ElectricalProjects } from './electricalProjects';
import { SolarProjects } from './solarProjects';
import { FireProjects } from './fireProjects';
import { NoMatch } from './NoMatch';
import { Layout } from './components/Layout';
import { Cube } from 'styled-loaders-react'

class App extends Component {

  state = {
    loading: true
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading: false
      })
    },1200)
  }

  render() {
    return (
        this.state.loading ? <Cube color="green" /> : 
      <React.Fragment>
        <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/faqs" component={Faqs} />
              <Route path="/electricalWorks" component={ElectricalWorks} />
              <Route path="/solarSolutions" component={SolarSolutions} />
              <Route path="/fireProtection" component={FireProtection} />
              <Route path="/operations&Maintenance" component={OperationsandMaintenance} />
              <Route path="/electricalProjects" component={ElectricalProjects} />
              <Route path="/solarProjects" component={SolarProjects} />
              <Route path="/fireProjects" component={FireProjects} />
              <Route component={NoMatch} />
            </Switch>
        </Router> 
      </React.Fragment>
    );
  }
}

export default App;
