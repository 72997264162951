import React from 'react';
import './components/style.css';
import './bootstrap-4.4.1-dist/css/bootstrap.min.css';
import styled from 'styled-components';

export const Contact = () => (
	<div id="home">
		<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
			<a class="navbar-brand" href="/"><img src="images/Logoo.png" /></a>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div class="collapse navbar-collapse" id="navbarResponsive">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item">
						<a class="nav-link" href="/">Home</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="about">About Us</a>
					</li>
			        <li class="nav-item dropdown">
			          <a class="nav-link" href="services" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">BUSINESS UNITS</a>
			          <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
			            <a class="dropdown-item" href="electricalWorks">Electrical Works</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="solarSolutions">Solar Solutions</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="fireProtection">Fire Protection</a>
			            <div class="dropdown-divider"></div>
			            <a class="dropdown-item" href="operations&Maintenance">Operations & Maintenance</a>
			          </div>
			        </li>
					        <li class="nav-item dropdown">
				            <a class="nav-link uppercase outline" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Projects</a>
				            <div class="dropdown-menu dropdown-menu-right">
				                <a class="dropdown-item uppercase" href="electricalProjects">ELECTRICAL</a>
				                <div class="dropdown-divider"></div>
				                <a class="dropdown-item uppercase" href="solarProjects">SOLAR</a>
				                <div class="dropdown-divider"></div>
				                <a class="dropdown-item uppercase" href="fireProjects">FIRE SAFETY</a>
				            </div>
				        </li>
					<li class="nav-item">
						<a class="nav-link" href="faqs">FAQS</a>
					</li>
					<li class="nav-item">
						<a class="nav-link active" href="#contact">Contact</a>
					</li>
				</ul>
			</div>
		</nav>



		<div id="contact" class="offset">
			<div class="">
				<div class="jumbotron">
					<div class="col-md-12 narrow text-center">
						<h3 class="heading">Contact US</h3>
						<div class="heading-underline">
						</div>
							<div class="row">
								<div class="col-md-9 contact">
									<h1 style={{color: 'green'}}>Oktagon East Africa Ltd</h1>
									<p><strong>ADDRESS:</strong> 78128 - 00507<br />08 Kampala Road Industrial Area</p>
									<p><strong>PHONE:</strong> +254 724 322874</p>
									<p><strong>PHONE:</strong> +254 737 322874</p>
									<p><strong>EMAIL:</strong> info@oktagoneastafrica.co.ke</p>
									<a href="#" target="_blank"><i class="fa fa-facebook-square"></i></a>
									<a href="#" target="_blank"><i class="fa fa-twitter-square"></i></a>
									<a href="#" target="_blank"><i class="fa fa-linkedin"></i></a>
								</div>
							</div> 
						<div class="container" style={{marginTop: '2rem'}}>
							<div class="col-md-6">
								<h1 style={{color: 'green'}}>Enquiry Form</h1>
						  		<form id="contact-form" method="post" action="#" role="form">

								    <div class="messages"></div>

								    <div class="controls">

								        <div class="row">
								            <div class="col-md-6">
								                <div class="form-group">
								                    <label for="form_name">Firstname *</label>
								                    <input id="form_name" type="text" name="name" class="form-control" placeholder="Please enter your firstname *" required="required" data-error="Firstname is required." />
								                    <div class="help-block with-errors"></div>
								                </div>
								            </div>
								            <div class="col-md-6">
								                <div class="form-group">
								                    <label for="form_lastname">Lastname *</label>
								                    <input id="form_lastname" type="text" name="surname" class="form-control" placeholder="Please enter your lastname *" required="required" data-error="Lastname is required." />
								                    <div class="help-block with-errors"></div>
								                </div>
								            </div>
								        </div>
								        <div class="row">
								            <div class="col-md-6">
								                <div class="form-group">
								                    <label for="form_email">Email *</label>
								                    <input id="form_email" type="email" name="email" class="form-control" placeholder="Please enter your email *" required="required" data-error="Valid email is required." />
								                    <div class="help-block with-errors"></div>
								                </div>
								            </div>
								            <div class="col-md-6">
								                <div class="form-group">
								                    <label for="form_need">Please specify your need *</label>
								                    <select id="form_need" name="need" class="form-control" required="required" data-error="Please specify your need.">
								                        <option value=""></option>
								                        <option value="Request quotation">Request quotation</option>
								                        <option value="Request order status">Request order status</option>
								                        <option value="Request copy of an invoice">Request copy of an invoice</option>
								                        <option value="Other">Other</option>
								                    </select>
								                    <div class="help-block with-errors"></div>
								                </div>
								            </div>
								        </div>
								        <div class="row">
								            <div class="col-md-12">
								                <div class="form-group">
								                    <label for="form_message">Message *</label>
								                    <textarea id="form_message" name="message" class="form-control" placeholder="Message  *" rows="4" required="required" data-error="Please, leave us a message."></textarea>
								                    <div class="help-block with-errors"></div>
								                </div>
								            </div>
								            <div class="col-md-12">
								                <input type="submit" class="btn btn-warning btn-send" value="Send message" />
								            </div>
								        </div>
								        <div class="row">
								            <div class="col-md-12">
								                <p class="text-muted">
								                    <strong>*</strong> These fields are required.
							                    </p>
								            </div>
								        </div>
								    </div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<a href="https://api.whatsapp.com/send?phone=254737322874&text=Hello" class="float" target="_blank">
		<i class="fa fa-whatsapp my-float"></i>
		</a>

		<div id="contact" class="offset">
		    <footer class="site-footer">
		      <div class="container">
		        <div class="row">
		          <div class="col-sm-12 col-md-6">
		            <h6>BUSINESS UNITS</h6><br/>
		            <ul class="footer-links">
		              <li><a href="electricalWorks">ELECTRICAL WORKS</a></li>
		              <li><a href="solarSolutions">SOLAR SOLUTIONS</a></li>
		              <li><a href="fireProtection">FIRE SAFETY</a></li>
		              <li><a href="operations&Maintenance">OPERATIONS & MAINTENANCE</a></li>
		            </ul>
		          </div>

		          <div class="col-xs-6 col-md-3">
		            <h6>PROJECTS</h6><br />
		            <ul class="footer-links">
		              <li><a href="electricalProjects">ELECTRICAL</a></li>
		              <li><a href="solarProjects">SOLAR</a></li>
		              <li><a href="fireProjects">FIRE SAFETY</a></li>
		            </ul>
		          </div>

		          <div class="col-xs-6 col-md-3">
		            <h6>Quick Links</h6><br />
		            <ul class="footer-links">
		              <li><a href="/">HOME</a></li>
		              <li><a href="about">ABOUT US</a></li>
		              <li><a href="faqs">FAQS</a></li>
		              <li><a href="#">CONTACT US</a></li>
		            </ul>
		          </div>
		        </div>
		        <hr />
		      </div>
		      <div class="container">
		        <div class="row">
		          <div class="col-md-8 col-sm-6 col-xs-12">
		            <p class="copyright-text">Copyright &copy; 2023 All Rights Reserved by 
		         <a href="/"> Oktagon East Africa</a>.
		            </p>
		          </div>

		          <div class="col-md-4 col-sm-6 col-xs-12">
		            <ul class="social-icons">
		              <li><a class="facebook"><i class="fa fa-facebook"></i></a></li>
		              <li><a class="twitter"><i class="fa fa-twitter"></i></a></li>
		              <li><a class="linkedin"><i class="fa fa-linkedin"></i></a></li> 
		              <li><a class="instagram"><i class="fa fa-instagram"></i></a></li>  
		            </ul>
		          </div>
		        </div>
		      </div>
			</footer>
		</div>
	</div>
)
